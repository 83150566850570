<template>
    <div class="error-page">
        <div class="error-code">4<span>0</span>4</div>
        <div class="error-desc">你所访问的页面不存在</div>
        <div class="error-handle">
         
             <router-link to="/">
               <button class="returnHome">
                返回首页
            </button>
            </router-link>
              <button class="returnHome error-btn" @click="goBack">
                返回上一页
            </button>
        </div>
    </div>
</template>

<script>
// function myFunction(){
// 	window.location.href="https://tw.huayeyun.cn/"
// }
// function goback(){
// window.history.back()
// }
    export default {
        methods: {
            goBack() {
                this.$router.go(-1);
            }
        }
    }
</script>
<style scoped>
.returnHome{
    color: #FFFFFF;
    background-color: #4458FE;
    border-color: #4458FE;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
   height: 40px;
    border: 1px solid #4458FE;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    width: 110px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;

}
    .error-page {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        /* background: #f3f3f3; */
    }

    .error-code {
        line-height: 1;
        font-size: 250px;
        font-weight: bolder;
        color: #2d8cf0;
    }

    .error-code span {
        color: #00a854;
    }

    .error-desc {
        font-size: 30px;
        color: #777;
    }

    .error-handle {
        margin-top: 30px;
        padding-bottom: 200px;
    }

    .error-btn {
        margin-left: 100px;
    }
</style>